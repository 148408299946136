import styled from "styled-components";

export const Loading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    height: 100dvh;
    width: 100vw;
    background-color: #FFFFFF;

    z-index: 1999;
    `;

export const LoadingText = styled.h1`
    font-size: 1rem;
    font-weight: 400;
    `;

export const LoadingLogo = styled.img`
position: relative;
height: 3rem;
width: auto;
filter: grayscale(100%) brightness(0%);
z-index: 1000;

@media screen and (max-width: 930px) {
    height: 2rem;
    margin-left: 35px;
    margin-top: 35px;
}
    `;
