import styled from "styled-components";

export const HeroSliderContainer = styled.div`
    aspect-ratio: 2/0.9;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (max-width: 600px) {
        aspect-ratio: 1/1;
    }
`;

export const Slide = styled.div`
     background-image: ${props => `url(${props.$backgroundImage})`};
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-in-out;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: opacity 1s ease-in-out;
    top: 0;
    left: 0;
    aspect-ratio: 2/0.9;

    @media (max-width: 600px) {
        aspect-ratio: 1/1;
    }
`;
