import { useState, useEffect } from 'react';
import client from '../../sanityClient';
import imageUrlBuilder from '@sanity/image-url';

// Set up the image URL builder
const builder = imageUrlBuilder(client);

function urlFor(source) {
   return builder.image(source).width(1920).height(1080).fit('crop').format('webp').quality(80);
}

function useHeroSlider() {
  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .fetch(`*[_type == "heroSlider"]{
        image {
          asset-> {
            _id,
            url
          }
        },
        altText
      }`)
      .then(data => {
        const slidesWithImageUrl = data.map(item => {
          return {
            ...item,
            imageUrl: urlFor(item.image.asset).url() // Generate image URL
          };
        });
        setSlides(slidesWithImageUrl);
        setTimeout(() => {
        setIsLoading(false);
        }, 1500);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  return { slides, isLoading, error };
}

export default useHeroSlider;
