import { Loading } from "./LoadingComponent.styles"
import { GridLoader } from "react-spinners"
import logo from "../../../Assets/Images/logo.png";
export default function LoadingComponent() {
    return (
        <Loading>
            <img src={logo} alt="Groupe Leclerc" width="250px" />
            <GridLoader color="#000000" size={15} />
        </Loading>
    )
}
