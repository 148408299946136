import { useState, useEffect } from 'react';
import client from '../../sanityClient';
import imageUrlBuilder from '@sanity/image-url';

// Set up the image URL builder
const builder = imageUrlBuilder(client);

function urlFor(source) {
  // Check if source exists before trying to access .image() method
  return source ? builder.image(source).width(800).fit('crop').format('webp').quality(80) : null;
}

function useProjects() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .fetch(`*[_type == "projets"]{
        title,
        slug,
        description,
        info_primary,
        info_secondary,
        categories,
        image {
          asset-> {
            _id,
            url
          }
        }
      }`)
      .then(data => {
        const projectsWithImageUrl = data.map(project => {
          const imageUrl = project.image?.asset ? urlFor(project.image.asset).url() : null;
          return {
            ...project,
            imageUrl: imageUrl
          };
        });
        setProjects(projectsWithImageUrl);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  return { projects, isLoading, error };
}

export default useProjects;
