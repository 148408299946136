import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 0;
  `;

export const SlideItem = styled.div`
  aspect-ratio: 7/5;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.a`
    position: relative;

    &:hover, &:focus {
        & h2{
            transition: all 0.3s ease-in-out;
            text-align: center;
            opacity: 1;
            width: 90%;
        }

        & img {
            transition: all 0.2s ease-in-out;
            filter: brightness(0.5) contrast(0.8); 
        }

        & div {
            transition: all 0.2s ease-in-out;
            transform: translateY(-30px);
            opacity: 0;
        }
    }
`;

export const Image = styled.img`
    display: block;
    user-select: none;
    filter: grayscale(1);
    object-fit: cover;
    aspect-ratio: 7/5;
    width: 100%;
`;

export const ImageTitle = styled.h2`
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
    opacity: 0;
`;

export const ArrowButton = styled.button`
  align-self: center;
  border: none;
  color: '#020202';
  cursor: 'pointer';
  font-size: '20px';
  height: 40px;
  width: 40px;
  transition: all 150ms ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #d6dae4;

  svg {
    height: 30px;
  }

    &:hover {   
        & svg {
            fill: #02020280;
            transform: scale(0.9);
        }
    }

    &:active {   
        & svg {
            fill: #020202F0;
            transform: scale(0.85);
        }
    }

    @media (max-width: 600px) {
        top: 50%;
        width: 35px;
        height: 35px;
    }
`;