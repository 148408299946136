import React, { useState, useEffect, useRef } from 'react';
import { HeroSliderContainer, Slide } from './HeroSlider.styles';
import useHeroSlider from '../../Assets/Hooks/getHeroSliderImages';
import LoadingComponent from '../Common/LoadingComponent/LoadingComponent';

export default function HeroSlider() {
    const [current, setCurrent] = useState(0);
    const { slides, isLoading, error } = useHeroSlider();
    const nextImageRef = useRef(null);

    useEffect(() => {
        if (slides.length === 0) return;

        const preloadNextImage = () => {
            const nextIndex = (current + 1) % slides.length;
            const nextImage = new Image();
            nextImage.src = slides[nextIndex].imageUrl;
            nextImageRef.current = nextImage;
        };

        preloadNextImage();

        const interval = setInterval(() => {
            setCurrent(current => (current + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [slides, current]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (error) {
        return <div>Error loading images</div>;
    }

    return (
        <HeroSliderContainer>
            {slides.map((slide, index) => (
                <Slide
                    key={slide.image.asset._id || index}
                    $backgroundImage={slide.imageUrl}
                    alt={slide.altText}
                    style={{
                        opacity: index === current ? 1 : 0,
                        zIndex: index === current ? 2 : 1
                    }}
                    loading={index === current || index === (current + 1) % slides.length ? 'eager' : 'lazy'}
                    srcSet={`${slide.imageUrl}&w=1920 1920w, ${slide.imageUrl}&w=1280 1280w, ${slide.imageUrl}&w=640 640w`}
                    sizes="100vw"
                />
            ))}
        </HeroSliderContainer>
    );
}