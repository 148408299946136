import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Image, ImageTitle, SlideItem, ArrowButton, Container, ImageContainer } from './ProjectsSlider.styles';
import { InfoTitle, Separator } from '../Common/Common.styles';
import { Link } from 'react-router-dom';

export default function ImageSlider({ data }) {


  const ArrowLeft = () => (
    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m10.828 11.997 4.95 4.95-1.414 1.414L8 11.997l6.364-6.364 1.414 1.414-4.95 4.95Z"></path>
  </svg>
);

const ArrowRight = () => (
    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m13.172 11.997-4.95-4.95 1.414-1.414L16 11.997 9.636 18.36l-1.414-1.414 4.95-4.95Z"></path>
  </svg>
);

  return (
    <Container>
      <InfoTitle>Nos projets</InfoTitle>
      <Separator />
      <Carousel
        className='carousel'
        swipeable={true}
        draggable={true}  
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        itemClass='carousel-item'
        centerMode={window.innerWidth > 600 ? true : false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 3000, min: 1801 },
            items: 3,
          },
          desktop: {
            breakpoint: { max: 1800, min: 1025 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 1,
          },
          mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1,
          },
        }}

        customLeftArrow={<ArrowButton style={{ left: "10px" }}><ArrowLeft /></ArrowButton>}
        customRightArrow={<ArrowButton style={{ right: "10px" }}><ArrowRight /></ArrowButton>}
      >
        {data?.map((dataItem, index) => (
          <SlideItem key={index} style={{ willChange: 'transform' }}>
            <ImageContainer key={dataItem.id} as={Link} to={`/projet/${dataItem.slug}`}>
              <Image src={dataItem.imageUrl} alt={dataItem.title} />
              <ImageTitle>{dataItem.title}</ImageTitle>
            </ImageContainer>
          </SlideItem>
        ))}
      </Carousel>
    </Container>
  );
}
